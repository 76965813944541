import EnvironmentalAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/environmental-allergies/EnvironmentalAllergiesTemplate';

import { ENVIRONMENTAL_ALLERGY, OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/EnvironmentalAllergies/EnvironmentalAllergiesTemplate',
  component: EnvironmentalAllergiesTemplate
};

const createStory = props => () => ({
  components: { EnvironmentalAllergiesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><environmental-allergies-template v-bind="$options.storyProps" /></div>'
});

export const maxAllergiesSelected = createStory({
  environmentalAllergies: [
    ENVIRONMENTAL_ALLERGY.CATS_OR_DOGS,
    ENVIRONMENTAL_ALLERGY.DUST_MITES,
    ENVIRONMENTAL_ALLERGY.POLLEN,
    ENVIRONMENTAL_ALLERGY.SUN
  ],
  otherEnvironmentalAllergies: ''
});

export const unselected = createStory({
  environmentalAllergies: [],
  otherEnvironmentalAllergies: ''
});

export const otherAllergiesSelected = createStory({
  environmentalAllergies: [OTHER_VALUE_BINDINGS.value],
  otherEnvironmentalAllergies: 'other allergies'
});
